import Rails from "@rails/ujs";
import * as ActiveStorage from "activestorage";

import React from "react";
import ReactDom from "react-dom";
import ReactRailsUJS from "react_ujs";
import PropTypes from "prop-types";

const componentRequireContext = require.context("../components", true);

ReactRailsUJS.useContext(componentRequireContext);
window.componentRequireContext = componentRequireContext;

ReactRailsUJS.mountComponents();

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";


window.Stimulus = Application.start();
const context = require.context("../controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));


import selectize from "selectize";
import DataTable from "datatables.net-dt";
import 'datatables.net-buttons';
import 'datatables.net-responsive-dt';
import "jszip";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/buttons.colVis.js";


import "swiper/swiper-bundle.css";
import "toastify-js/src/toastify.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "datatables.net-dt/css/jquery.dataTables.css";
import "normalize-css";

require.context("../images", true);

import "../stylesheets/application.scss";

import "trix";
import "@rails/actiontext";
import "chartkick";
import "chart.js";
import "youtube";

import("../src/tippy_popovers");
import("../src/landing_page_slider");
import("../src/datatiles");
import("../src/uppy");
import("../src/uppy_document");
import("../src/camps");
import("../src/mailer_dashboard");
import("../src/sms_notifications");
import("../src/date_select_wrapper.jsx");

ActiveStorage.start();
Rails.start();


// import download from 'downloadjs';

// async function modifyPdf() {
//   const url = 'https://pdf-lib.js.org/assets/with_update_sections.pdf'
//   const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())

//   const pdfDoc = await PDFDocument.load(existingPdfBytes)
//   const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

//   const pages = pdfDoc.getPages()
//   const firstPage = pages[0]
//   const { width, height } = firstPage.getSize()
//   firstPage.drawText('This text was added with JavaScript!', {
//     x: 5,
//     y: height / 2 + 300,
//     size: 50,
//     font: helveticaFont,
//     color: rgb(0.95, 0.1, 0.1),
//     rotate: degrees(-45),
//   })

//   const pdfBytes = await pdfDoc.save()
// }

// document.addEventListener('DOMContentLoaded', () => {
//   let coolButton = document.querySelector('#coolio')
//   let filledOutPdf = document.querySelector("iframe")
//   let url = filledOutPdf.src
//   coolButton.addEventListener("click", (event) => {
//       event.preventDefault()
//       console.log('cool')
//       flattenForm(url)
//   });
// })

// async function flattenForm(pdfUrl) {
//   const formPdfBytes = await fetch(pdfUrl).then(res => res.arrayBuffer())
//   const pdfDoc = await PDFDocument.load(formPdfBytes)
//   const form = pdfDoc.getForm()
//   // {:Participant=>"Howard E O'Leary", :"Participants Gender"=>"", :Grade=>"5th", :ParentGuardian=>"Buck", :Address=>"3509 34th NW", :"City  State  Zip Code"=>"Washington DC - District of Columbia 20008", :"Telephone Home"=>"202-321-7516", :"Emergency contact if parent or guardian is unavailable"=>"fdsafadsfads", :"Participants Age"=>24, :"Parent/Guardian's Work telephone"=>"202-321-7516", :"Cell Phone"=>"202-321-7516", :"Emergency Contact's phone"=>"202-321-7516", :"Name of Event"=>"Bambo Jam 200", :"Sponsor / Organizer / Operator"=>"Operator", :"Date of Event_af_date"=>"3/8/22 - 3/10/22", :"Location of Event"=>"", :"Event activities"=>"sports-performance", :Initials=>nil, :Initials_2=>nil, :Initials_3=>nil, :Initials_4=>nil, :Initials_5=>nil, :Initials_6=>nil}
//   form.getTextField('Participant').setText("Howard E O'Leary");
//   // form.getRadioGroup('Group2').select('Choice1');
//   // form.getRadioGroup('Group3').select('Choice3');
//   // form.getRadioGroup('Group4').select('Choice1');
//   // form.getCheckBox('Check Box3').check();
//   // form.getCheckBox('Check Box4').uncheck();
//   // form.getDropdown('Dropdown7').select('Infinity');
//   // form.getOptionList('List Box6').select('Honda');

//   form.flatten();

//   const pdfBytes = await pdfDoc.save()
//   download(pdfBytes, "pdf-lib_form_creation_example.pdf", "application/pdf");
// }


// async function fillForm() {
//   let pdfUrl = "https://firewood-misc-storage.s3.us-east-1.amazonaws.com/combined.pdf"

//   const formPdfBytes = await fetch(pdfUrl).then(res => res.arrayBuffer())
//   const pdfDoc = await PDFDocument.load(formPdfBytes)
//   const form = pdfDoc.getForm()

//   console.log(form)
//   console.log(form.acroForm)

//   const pdfBytes = await pdfDoc.save()

//   // download(formPdfBytes, "pdf-lib_form_creation_example.pdf", "application/pdf");

//   // const nameField = form.getTextField('CharacterName 2')
//   // const ageField = form.getTextField('Age')
//   // const heightField = form.getTextField('Height')
//   // const weightField = form.getTextField('Weight')
//   // const eyesField = form.getTextField('Eyes')
    // form.flatten();

    // const pdfBytes = await pdfDoc.save()
// }

// fillForm()
